export function replaceWithSubSuperscripts(input) {
    // Superscript mappings for numbers 0-9 and some letters
    const superscripts = {
        '0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴',
        '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹',
        'a': 'ᵃ', 'b': 'ᵇ', 'c': 'ᶜ', 'd': 'ᵈ', 'e': 'ᵉ',
        'f': 'ᶠ', 'g': 'ᵍ', 'h': 'ʰ', 'i': 'ⁱ', 'j': 'ʲ',
        'k': 'ᵏ', 'l': 'ˡ', 'm': 'ᵐ', 'n': 'ⁿ', 'o': 'ᵒ',
        'p': 'ᵖ', 'r': 'ʳ', 's': 'ˢ', 't': 'ᵗ', 'u': 'ᵘ',
        'v': 'ᵛ', 'w': 'ʷ', 'x': 'ˣ', 'y': 'ʸ', 'z': 'ᶻ'
    };

    // Subscript mappings for numbers 0-9 and some letters
    const subscripts = {
        '0': '₀', '1': '₁', '2': '₂', '3': '₃', '4': '₄',
        '5': '₅', '6': '₆', '7': '₇', '8': '₈', '9': '₉',
        'a': 'ₐ', 'e': 'ₑ', 'h': 'ₕ', 'i': 'ᵢ', 'j': 'ⱼ',
        'k': 'ₖ', 'l': 'ₗ', 'm': 'ₘ', 'n': 'ₙ', 'o': 'ₒ',
        'p': 'ₚ', 'r': 'ᵣ', 's': 'ₛ', 't': 'ₜ', 'u': 'ᵤ',
        'v': 'ᵥ', 'x': 'ₓ'
    };

    input = input = input.replace(/\[\[([^\]]+)\]\]/g, (match, target) => {
        // Handle double braces for full superscripts ([[m^{abc123}]])
        let targetResult = target.replace(/\^\{([^}]+)\}/g, (match, p1) => {
            let result = '';
            for (let char of p1) {
                result += superscripts[char] ? superscripts[char]: char; // Convert each character to superscript
            }
            return result;
        });

        // Handle double braces for partial superscripts ([[m^2]])
        targetResult = targetResult.replace(/\^([^{}])/g, (match, char) => {
            const result = superscripts[char] ? superscripts[char]: char; // Convert each character to superscript
            return result;
        });

        // Handle double braces for full subscripts ([[m_{abc123}]])
        targetResult = targetResult.replace(/_\{([^}]+)\}/g, (match, p1) => {
            let result = '';
            for (let char of p1) {
                result += subscripts[char] ? subscripts[char]: char; // Convert each character to superscript
            }
            return result;
        });

        // Handle double braces for partial superscripts ([[m_2]])
        targetResult = targetResult.replace(/_([^{}])/g, (match, char) => {
            const result = subscripts[char] ? subscripts[char]: char; // Convert each character to superscript
            return result;
        });

        return targetResult;
    });

    return input;
}
import React from 'react';
import { Box, Typography} from '@mui/material';
import * as Icons from '@mui/icons-material';

const AreaHeader = ({activeStep, steps, descriptionTxt}) => {
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;
  const Icon = Icons[area.icon] || Icons.InfoOutlined;

  const description = typeof(descriptionTxt) === "string" ? descriptionTxt : "";

  return (
    <Box>
        <Box display="flex" alignItems="center">
            <Icon color="primary" />
            <Typography variant="h5" color="primary" style={{ padding: '10px' }}>
            {area.name}
            </Typography>
            <Box sx={{ marginLeft: 'auto', backgroundColor: 'primary.main', color: 'white', px: 2, py: 0.5, borderRadius: 2, display: { xs: 'block', sm: 'none' } }}>
            <Typography variant="body1">
                {activeStep + 1}/{steps.length}
            </Typography>
            </Box>
    
    </Box>
        <Typography display="flex" variant="body1" color="textSecondary" sx={{ marginBottom: 5 }}>
            {description}
        </Typography>
        
    </Box>
  );
};

export default AreaHeader;
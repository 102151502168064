import React from "react";
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  Box,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { PostHogFeature } from "posthog-js/react";
import { replaceWithSubSuperscripts } from "../utils/textUtils";

import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";

function AutoFillButton({ question, handleAutoFill, disabled }) {
  if (!question.is_auto_fillable) return null;
  return (
    <PostHogFeature flag="frontent-form-AutoFillButton" match={true}>
      <IconButton onClick={() => handleAutoFill(question)} disabled={disabled}>
        <CloudDownloadOutlinedIcon
          color={disabled ? "disabled" : "secondary"}
        />
      </IconButton>
    </PostHogFeature>
  );
}

const NumericPercentageFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      allowNegative={false}
      allowLeadingZeros={false}
      valueIsNumericString
      decimalScale={1}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue ? floatValue <= 100 : true;
      }}
    />
  );
});

const NumericNumberFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      allowNegative={false}
      allowLeadingZeros={false}
      valueIsNumericString
      decimalScale={1}
    />
  );
});

const IntegerNumberFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      allowNegative={false}
      allowLeadingZeros={false}
      valueIsNumericString
      decimalScale={0}
    />
  );
});

NumericPercentageFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumericNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

IntegerNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FormSection = ({
  questions,
  activeStep,
  steps,
  formValues,
  setQuestionAnswer,
  setUnitAnswer,
  isDataModified,
  setIsDataModified,
  handleAutoFill,
  isFieldAutoFilling,
}) => {
  if (!questions || questions.length === 0 || "error" in questions) return null;
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;
  const areaId = area.id;
  const areaQuestions = questions.filter(
    (question) => question.area_id === areaId
  ); // eslint-disable-line eqeqeq
  const Icon = Icons[area.icon] || Icons.InfoOutlined;

  const sortedAreaQuestions = areaQuestions.sort((a, b) => {
    if (a.ordering_number === null && b.ordering_number === null) return 0; // Keep original order if both are null
    if (a.ordering_number === null) return 1; // a should come after b if a is null
    if (b.ordering_number === null) return -1; // b should come after a if b is null
    return a.ordering_number - b.ordering_number; // normal numeric sort
  });

  return (
    <>
      <form>
        <Box display="flex" alignItems="center">
          <Icon color="primary" />
          <Typography variant="h5" color="primary" style={{ padding: "10px" }}>
            {area.name}
          </Typography>

          <Box
            sx={{
              marginLeft: "auto",
              backgroundColor: "primary.main",
              color: "white",
              px: 2,
              py: 0.5,
              borderRadius: 2,
              display: { xs: "block", sm: "none" },
            }}
          >
            <Typography variant="body1">
              {activeStep + 1}/{steps.length}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ marginBottom: 5 }}
        >
          {area.description}
        </Typography>
        {sortedAreaQuestions.map((question) => {
          const questionValue = formValues[`value_${question.id}`] ?? "";
          const units = question.units;
          let questionUnit = units.find(
            (u) => u.id === Number(formValues[`unit_${question.id}`])
          );
          if (questionUnit === undefined) {
            questionUnit = question.default_unit;
          }
          if (question.format === "bool") {
            return (
              <Grid
                container
                spacing={2}
                key={question.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id={`label_${question.id}`}>
                      {question.label}
                    </InputLabel>
                    <Select
                      labelId={`label_${question.id}`}
                      label={question.label}
                      variant="outlined"
                      fullWidth
                      value={questionValue}
                      name={`value_${question.id}`}
                      onChange={(e) => {
                        setQuestionAnswer(question.id, e.target.value);
                      }}
                    >
                      <MenuItem value={1}>Ja</MenuItem>
                      <MenuItem value={0}>Nej</MenuItem>
                    </Select>
                  </FormControl>

                  <input
                    type="hidden"
                    name={`unit_${question.id}`}
                    value={questionUnit?.id}
                  />
                </Grid>
              </Grid>
            );
          } else if (question.format === "percent") {
            return (
              <Grid
                container
                spacing={2}
                key={question.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
                  <TextField
                    type="standard"
                    label={question.label}
                    variant="outlined"
                    fullWidth
                    value={questionValue}
                    name={`value_${question.id}`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputComponent: NumericPercentageFormat,
                      inputProps: { min: 0, max: 100 },
                    }}
                    onChange={(e) => {
                      setQuestionAnswer(question.id, e.target.value);
                    }}
                  />
                </Grid>
                {question.is_auto_fillable ? (
                  <Grid item xs={0.5}>
                    <AutoFillButton
                      question={question}
                      handleAutoFill={handleAutoFill}
                      disabled={isFieldAutoFilling[question.id] || false}
                    />
                  </Grid>
                ) : null}
                <input
                  type="hidden"
                  name={`unit_${question.id}`}
                  value={questionUnit?.id}
                />
              </Grid>
            );
          } else if (question.format === "integer") {
            return (
              <Grid
                container
                spacing={2}
                key={question.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
                  <TextField
                    type="standard"
                    label={question.label}
                    variant="outlined"
                    fullWidth
                    value={questionValue}
                    name={`value_${question.id}`}
                    disabled={isFieldAutoFilling[question.id] || false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {replaceWithSubSuperscripts(questionUnit?.label)}
                        </InputAdornment>
                      ),
                      inputComponent: IntegerNumberFormat,
                    }}
                    onChange={(e) => {
                      setQuestionAnswer(question.id, e.target.value);
                    }}
                  />
                </Grid>
                {question.is_auto_fillable ? (
                  <Grid item xs={0.5}>
                    <AutoFillButton
                      question={question}
                      handleAutoFill={handleAutoFill}
                      disabled={isFieldAutoFilling[question.id] || false}
                    />
                  </Grid>
                ) : null}
                <input
                  type="hidden"
                  name={`unit_${question.id}`}
                  value={questionUnit?.id}
                />
              </Grid>
            );
          }

          // (Default) Format is float.
          if (units.length === 1) {
            return (
              <Grid
                container
                spacing={2}
                key={question.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
                  <TextField
                    type="standard"
                    label={question.label}
                    variant="outlined"
                    fullWidth
                    value={questionValue}
                    name={`value_${question.id}`}
                    disabled={isFieldAutoFilling[question.id] || false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {replaceWithSubSuperscripts(questionUnit?.label)}
                        </InputAdornment>
                      ),
                      inputComponent: NumericNumberFormat,
                      inputProps: { min: 0, max: 100 },
                    }}
                    onChange={(e) => {
                      setQuestionAnswer(question.id, e.target.value);
                    }}
                  />
                </Grid>
                {question.is_auto_fillable ? (
                  <Grid item xs={0.5}>
                    <AutoFillButton
                      question={question}
                      handleAutoFill={handleAutoFill}
                      disabled={isFieldAutoFilling[question.id] || false}
                    />
                  </Grid>
                ) : null}
                <input
                  type="hidden"
                  name={`unit_${question.id}`}
                  value={questionUnit?.id}
                />
              </Grid>
            );
          } else {
            return (
              <Grid
                container
                spacing={2}
                key={question.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={question.is_auto_fillable ? 8.5 : 9}>
                  <TextField
                    type="standard"
                    label={question.label}
                    variant="outlined"
                    fullWidth
                    value={questionValue}
                    name={`value_${question.id}`}
                    InputProps={{
                      inputComponent: NumericNumberFormat,
                      inputProps: { min: 0, max: 100 },
                    }}
                    onChange={(e) => {
                      setQuestionAnswer(question.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3} id={`label_unit_${question.id}`}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Vælg enhed</InputLabel>
                    <Select
                      id={`select_unit_${question.id}`}
                      labelId={`label_unit_${question.id}`}
                      label="Vælg enhed"
                      variant="outlined"
                      value={questionUnit?.id}
                      name={`unit_${question.id}`}
                      onChange={(e) => {
                        setUnitAnswer(question.id, e.target.value);
                      }}
                    >
                      {units.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          {replaceWithSubSuperscripts(unit.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {question.is_auto_fillable ? (
                  <Grid item xs={0.5}>
                    <AutoFillButton
                      question={question}
                      handleAutoFill={handleAutoFill}
                      disabled={isFieldAutoFilling[question.id] || false}
                    />
                  </Grid>
                ) : null}
              </Grid>
            );
          }
        })}
      </form>
    </>
  );
};

export default FormSection;

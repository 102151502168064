// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const hasToken = !!localStorage.getItem('token'); // Check if token exists in local storage.
  const hasUser = !!localStorage.getItem('user'); // Check if user exists in local storage.

  if (!hasToken || !hasUser) {
    return <Navigate to="/logind" />;
  }

  return children;
};

export default ProtectedRoute;

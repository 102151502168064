// src/pages/Settings.js
import React, { useState, useEffect } from "react";
import { usePermissions } from "../contexts/PermissionsContext";
import { useRoles } from "../contexts/RolesContext"; // Assuming you have a similar context for roles

import {
  Container,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Alert,
} from "@mui/material";

import { Launch, Storage } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Settings = ({ isDataModified, setIsDataModified }) => {
  const [banks, setBanks] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [userData, setUserData] = useState({
    name: user.name,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const [companyData, setCompanyData] = useState({
    email: user?.company?.email,
    website: user?.company?.website,
    phone: user?.company?.phone,
    logo: user?.company?.logo,
    bank_vendor_id: user?.company?.bank_vendor_id,
  });

  const permissions = usePermissions();
  const roles = useRoles();
  const environment = process.env.NODE_ENV;

  const isSysAdmin = roles.includes("sys_admin");
  const hasPartialDbSyncPermission = permissions.includes("partial_db_sync");

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/bankvendors", {
      headers: {
        Content: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBanks(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDataModified(true);
    if (tabIndex === 0) {
      setUserData({ ...userData, [name]: value });
    } else if (tabIndex === 1) {
      setCompanyData({ ...companyData, [name]: value });
    } else if (tabIndex === 2) {
      setPasswordData({ ...passwordData, [name]: value });
    }
  };

  const handleTabChange = (event, newValue) => {
    if (isDataModified) {
      if (
        window.confirm(
          "Du har ændringer, som ikke er gemt. Er du sikker på, at du vil forlade siden?"
        )
      ) {
        setError("");
        setSuccess("");
        setIsDataModified(false);

        // Reset fields.
        setUserData({
          name: user.name,
        });

        setCompanyData({
          email: user?.company?.email,
          website: user?.company?.website,
          phone: user?.company?.phone,
          logo: user?.company?.logo,
          bank_vendor_id: user?.company?.bank_vendor_id,
        });

        setPasswordData({
          password: "",
          new_password: "",
          confirm_password: "",
        });

        setTabIndex(newValue);
      }
    } else {
      setError("");
      setSuccess("");
      setTabIndex(newValue);
    }
  };

  const handleAccountSave = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + "/me", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.id === user.id) {
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem(
            "accountingYear",
            data.company.accounting_years[0].id
          );
          setSuccess("Dine ændringer er gemt");
          setIsDataModified(false);
        } else {
          let errorMessage = "";
          for (const key in data) {
            errorMessage += data[key][0] + "\r\n";
          }
          setError("Udfyld alle felter og prøve igen:\r\n" + errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Der skete en fejl");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCompanySave = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + "/me/company", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(companyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.id === user.company.id) {
          user.company = data;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("accountingYear", data.accounting_years[0].id);
          setSuccess("Dine ændringer er gemt");
          setIsDataModified(false);
        } else {
          let errorMessage = "";
          for (const key in data) {
            errorMessage += data[key][0] + "\r\n";
          }
          setError("Udfyld alle felter og prøve igen:\r\n" + errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Der skete en fejl");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (passwordData.new_password !== passwordData.confirm_password) {
      setPasswordConfirmError("Adgangskoderne er ikke ens");
      setLoading(false);
      return;
    }

    fetch(process.env.REACT_APP_API_URL + "/me", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        password: passwordData.password,
        new_password: passwordData.new_password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.id === user.id) {
          setSuccess("Dine adgangskode er opdateret");
          setIsDataModified(false);
        } else {
          let errorMessage = "";
          for (const key in data) {
            errorMessage += data[key][0] + "\r\n";
          }
          setError(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Der skete en fejl");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePasswordConfirmChange = (e) => {
    const value = e.target.value;
    if (passwordData.new_password !== value) {
      setPasswordConfirmError("Adgangskoderne er ikke ens");
    } else {
      setPasswordConfirmError("");
      setPasswordData({ ...passwordData, confirm_password: e.target.value });
    }
  };

  const handlePartialDbSync = () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    fetch(process.env.REACT_APP_API_URL + "/admin/partial-db-sync", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Partial DB sync successful:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }}>
        Indstillinger
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="settings tabs"
        sx={{ mb: 2 }}
      >
        <Tab label="Profil" />
        <Tab label="Virksomhed" />
        <Tab label="Adgangskode" />
        <Tab label="Fakturering & Abonnement" />
        {isSysAdmin && <Tab label="System administration" />}
      </Tabs>
      {tabIndex === 0 && (
        <form onSubmit={handleAccountSave}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={user.email}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              fullWidth
              label="Name"
              name="name"
              value={userData.name}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Gem ændringer
            </Button>
          </Box>
        </form>
      )}
      {tabIndex === 1 && (
        <form onSubmit={handleCompanySave}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="CVR nummer"
              name="vatNumber"
              value={user?.company?.vat_number}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Virksomhedsnavn"
              name="companyName"
              value={user?.company?.name}
              disabled
            />
          </Box>
          {/* <Box mb={2}>
            <TextField
              fullWidth
              label="Virksomheds email"
              name="email"
              value={companyData.email}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Hoved telefon nummer"
              name="phone"
              value={companyData.phone}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Hjemmeside"
              name="website"
              value={companyData.website}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Logo"
              name="logo"
              value={companyData.logo}
              onChange={handleChange}
              disabled={loading}
            />
          </Box> */}
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="bank-label">Bank *</InputLabel>
              <Select
                required
                labelId="bank-label"
                id="bank"
                label="Bank"
                value={companyData.bank_vendor_id}
                onChange={(e) =>
                  setCompanyData({
                    ...companyData,
                    bank_vendor_id: e.target.value,
                  })
                }
                disabled={loading}
              >
                {banks.map((bank) => (
                  <MenuItem key={bank.id} value={bank.id}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Gem ændringer
            </Button>
          </Box>
        </form>
      )}
      {tabIndex === 2 && (
        <form onSubmit={handlePasswordChange}>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Din adgangskode"
              type={showCurrentPassword ? "text" : "password"}
              name="password"
              value={userData.password}
              onChange={handleChange}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setCurrentShowPassword(!showCurrentPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Ny adgangskode"
              type={showPassword ? "text" : "password"}
              name="new_password"
              autoComplete="new-password"
              helperText="Adgangskoden skal være mindst 8 tegn lang"
              value={userData.new_password}
              onChange={handleChange}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Bekræft adgangskode"
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              autoComplete="new-password"
              onChange={handlePasswordConfirmChange}
              error={!!passwordConfirmError}
              helperText={passwordConfirmError}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Opdater adgangskode
            </Button>
          </Box>
        </form>
      )}
      {tabIndex === 3 && (
        <Box mb={12}>
          <Stack spacing={2}>
            <Alert
              severity="info"
              sx={{
                mt: 2,
                backgroundColor: (theme) => theme.palette.secondary.light,
                "& .MuiAlert-icon": {
                  color: (theme) => theme.palette.secondary.contrastText,
                },
                color: (theme) => theme.palette.secondary.contrastText,
              }}
            >
              Du kan administrere din betalingsmetode, se fakturaer og ændre dit
              abonnement via Stripe betalingsportalen.
            </Alert>

            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Launch />}
                href={
                  process.env.NODE_ENV === "development"
                    ? "https://billing.stripe.com/p/login/test_00g3ewf6g4JH1wsbII"
                    : "https://billing.stripe.com/p/login/28obMv2wpb7agRq5kk"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Åbn Stripe betalingsportal
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      {tabIndex === 4 && (
        <Box mb={12}>
          <Stack spacing={2}>
            <Alert
              severity="info"
              sx={{
                mt: 2,
                backgroundColor: (theme) => theme.palette.secondary.light,
                "& .MuiAlert-icon": {
                  color: (theme) => theme.palette.secondary.contrastText,
                },
                color: (theme) => theme.palette.secondary.contrastText,
              }}
            >
              På denne side kan du udføre systemprocesser afhængigt af dine
              tilladelser.
            </Alert>

            {(environment === "test" || environment === "development") &&
              hasPartialDbSyncPermission && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Storage />}
                    onClick={handlePartialDbSync}
                  >
                    Partiel DB sync
                  </Button>
                </Box>
              )}
          </Stack>
        </Box>
      )}
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      {success && (
        <Typography variant="body2" color="success">
          {success}
        </Typography>
      )}
    </Container>
  );
};

export default Settings;

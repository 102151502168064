// PermissionsContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const PermissionsContext = createContext();

export const usePermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    // Fetch permissions from your backend
    const token = localStorage.getItem("token");
    if (!token) return;

    fetch(process.env.REACT_APP_API_URL + "/me/permissions", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.permissions && Array.isArray(data?.permissions))
          setPermissions(data.permissions);
      });
  }, []);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};

// src/pages/StartPage.js
import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import logo from "../logo-white.jpg";

const TermsOfService = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="max-h-[80vh] border border-gray-300 overflow-y-auto p-10"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Licensaftale for ESG Nøgletalsrapportering
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">1. Parterne</Typography>
              <Typography paragraph>
                Denne aftale indgås mellem DitESG, herefter benævnt
                "Licensgiveren," og den person eller virksomhed, der opretter et
                abonnement gennem vores platform, herefter benævnt
                "Licenstageren."
              </Typography>
              <Typography paragraph>
                Ved at oprette et abonnement erklærer Licenstageren, at de er
                bemyndiget til at repræsentere sig selv eller den virksomhed, de
                handler på vegne af, og accepterer disse vilkår og betingelser.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">2. Aftalens Genstand</Typography>
              <Typography paragraph>
                Licensgiveren giver Licenstageren ret til at bruge det
                cloud-baserede program, der muliggør indtastning af
                virksomhedsdata og generering af en ESG nøgletalsrapport
                ("Softwaren").
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">3. Licensens Omfang</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensen er ikke-eksklusiv og kan ikke overdrages." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Softwaren må kun bruges til Licenstagerens interne forretningsformål." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">4. Abonnementsperiode</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Aftalen løber i den aftalte periode fra ikrafttrædelsesdatoen." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Abonnementet fornyes automatisk for yderligere perioder, medmindre det opsiges via Stripe.com." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">5. Betaling</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgebyret betales forud og trækkes automatisk ved aftalens indgåelse." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Efterfølgende trækkes licensgebyret automatisk ved hver fornyelse efter den aftalte periode." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Alle betalinger behandles via Stripe.com, som sikrer en sikker og pålidelig betalingsløsning." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Det er dit ansvar at sikre, at de angivne betalingsoplysninger er korrekte og opdaterede." />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">
                6. Support, Opdateringer og Opgraderinger
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren leverer teknisk support via DitESG eller email i almindelig arbejdstid." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Softwaren opdateres og opgraderes løbende for at forbedre funktionalitet og sikkerhed." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licenstageren vil blive informeret om større ændringer i Softwaren." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">
                7. Sikkerhed og Databeskyttelse
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren forpligter sig til at beskytte Licenstagerens data." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Data opbevares på anerkendte europæiske datacentre i overensstemmelse med gældende databeskyttelseslovgivning." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Parterne skal overholde GDPR og andre relevante databeskyttelsesregler." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">
                8. Overførsel af Data til Licenstagerens Bank
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ved anvendelse af Softwaren kan Licenstageren angive sin bank og give samtykke til, at DitESG videresender relevante data til denne bank." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Overførslen af data sker kun med Licenstagerens udtrykkelige samtykke og i overensstemmelse med gældende databeskyttelseslovgivning." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren sikrer, at alle dataoverførsler sker sikkert og fortroligt." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">9. Force Majeure</Typography>
              <Typography paragraph>
                Ingen af parterne er ansvarlige for manglende opfyldelse af
                aftalen som følge af forhold uden for deres kontrol, såsom
                naturkatastrofer, krig eller offentlige restriktioner.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">10. Underleverandører</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren kan benytte underleverandører til at opfylde sine forpligtelser under denne aftale." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren er ansvarlig for underleverandørers ydelser som for sine egne." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">11. Ansvarsbegrænsning</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren er ikke ansvarlig for indirekte tab, herunder tabt fortjeneste, data eller goodwill." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiverens samlede ansvar er begrænset til det betalte licensgebyr for den indeværende abonnementsperiode." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">12. Skadesløsholdelse</Typography>
              <Typography paragraph>
                Licenstageren skal skadesløsholde Licensgiveren for ethvert
                krav, tab eller skade som følge af Licenstagerens uberettigede
                brug af Softwaren.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">13. Brugeransvar</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licenstageren er ansvarlig for korrekt indtastning og vedligeholdelse af data i Softwaren." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licenstageren må ikke omgå sikkerhedsforanstaltninger eller foretage reverse engineering af Softwaren." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">
                14. Brug af Licenstagerens Data
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licensgiveren må anvende Licenstagerens data i anonymiseret og aggregeret form til statistiske og analytiske formål." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Personhenførbare data vil ikke blive videregivet til tredjeparter uden Licenstagerens samtykke, med undtagelse af overførsler beskrevet i afsnit 8." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">15. Fortrolighed</Typography>
              <Typography paragraph>
                Parterne forpligter sig til at holde alle fortrolige oplysninger
                hemmelige og kun bruge dem i forbindelse med aftalens
                opfyldelse.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">16. Immaterielle Rettigheder</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Alle rettigheder til Softwaren forbliver hos Licensgiveren." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Licenstageren får ingen ejendomsret til Softwaren eller tilhørende dokumentation." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">17. Opsigelse</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Abonnementet kan opsiges af begge parter til enhver tid. Opsigelsen træder i kraft ved udløbet af den igangværende abonnementsperiode, og der foretages ingen yderligere opkrævninger." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ved opsigelse mister du adgang til licensen og tilknyttede tjenester, når den aktuelle abonnementsperiode udløber." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ved væsentlig misligholdelse kan aftalen opsiges med øjeblikkelig virkning, uden refusion for den resterende abonnementsperiode." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Opsigelse kan foretages via din konto på platformen eller ved at kontakte os skriftligt." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">18. Lovvalg og Værneting</Typography>
              <List>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Aftalen er underlagt dansk ret." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Eventuelle tvister afgøres ved de danske domstole med Licensgiverens hjemting som værneting." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">19. Ændringer</Typography>
              <List>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Vi forbeholder os retten til at foretage ændringer i disse vilkår og betingelser. Eventuelle væsentlige ændringer vil blive meddelt dig skriftligt eller via den e-mailadresse, der er knyttet til din konto, mindst 30 dage før ændringerne træder i kraft." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Fortsat brug af abonnementet efter ændringerne træder i kraft betragtes som en accept af de opdaterede vilkår." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Hvis du ikke accepterer de ændrede vilkår, kan du opsige dit abonnement inden ændringerne træder i kraft i overensstemmelse med afsnit 17." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Vi opfordrer dig til regelmæssigt at gennemgå disse vilkår for at holde dig opdateret." />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsOfService;

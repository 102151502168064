import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import useVerifyAccess from "../hooks/useVerifyAccess";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../contexts/SnackbarContext";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Report = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [accountingYearId] = useState(() =>
    localStorage.getItem("accountingYear")
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { hasAccess, hasAccessIsLoading } = useVerifyAccess();

  // Initialize the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (hasAccessIsLoading) return;

    if (!hasAccess) {
      showSnackbar(
        "Hov! Du mangler licens før du kan download rapporten.",
        "success"
      );
      navigate("/koeb-licens");
    }
  }, [navigate, showSnackbar, hasAccess, hasAccessIsLoading]);

  useEffect(() => {
    const fetchPdf = async () => {
      if (!accountingYearId) {
        setError("Accounting year ID is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await fetch(
          `${apiUrl}/me/company/report/${accountingYearId}/show`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/pdf",
            },
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        } else {
          // Attempt to parse error message from response
          let errorMessage = `Error: ${response.statusText}`;
          try {
            const errorData = await response.json();
            if (errorData.message) {
              errorMessage = `Error: ${errorData.message}`;
            }
          } catch (parseErr) {
            console.error("Error parsing error response JSON:", parseErr);
          }
          setError(errorMessage);
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setError("An unknown error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, [accountingYearId]);

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Report</title>
      </Helmet>
      <div
        style={{
          height: "calc(100vh - 73px)",
          width: "100%",
        }}
      >
        {isLoading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {pdfUrl && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Report;

import React, { useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

const VideoContainer = styled(Box)({
  width: "100%",
  position: "relative",
});

const StyledVideo = styled("video")({
  width: "100%",
  outline: "none",
  "::cue": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
  },
  // Hide specific controls
  "::-webkit-media-controls-playback-rate-button": {
    display: "none",
  },
  "::-webkit-media-controls-overflow-menu-button": {
    display: "none",
  },
  "::-webkit-media-controls-cast-button": {
    display: "none",
  },
  "::-webkit-media-controls-enclosure": {
    overflow: "hidden",
  },
});

const PlayButton = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80px",
  height: "80px",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1,
  "&::before": {
    content: '""',
    display: "block",
    width: "0",
    height: "0",
    borderLeft: "20px solid white",
    borderTop: "12px solid transparent",
    borderBottom: "12px solid transparent",
  },
});

const IntroVideoPlayer = ({ sx }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      const track = video.textTracks[0];

      if (track) {
        track.mode = "showing";

        // Debug logging
        console.log("Track loaded:", {
          label: track.label,
          kind: track.kind,
          language: track.language,
          mode: track.mode,
        });
      }

      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);

      return () => {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <VideoContainer sx={sx}>
      {!isPlaying && <PlayButton onClick={handlePlayButtonClick} />}
      <StyledVideo
        ref={videoRef}
        controls
        controlsList="nodownload"
        disablePictureInPicture
      >
        <source
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/storage/introduction/intro_video.mp4`}
          type="video/mp4"
        />
      </StyledVideo>
    </VideoContainer>
  );
};

export default IntroVideoPlayer;

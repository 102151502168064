import React from "react";
import { Typography, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactMarkdown from "react-markdown";
import DownloadButton from "../components//DownloadFile";

const TipSection = ({
  activeStep,
  steps,
  displayTipHeader = true,
  displayTipText = true,
  displayFiles = true,
  displayImage = true,
}) => {
  if (!steps || steps.length === 0 || "error" in steps) return null;
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;

  return (
    <Box display="flex" flexDirection="column" gap="0.2rem">
      {displayTipHeader && (
        <Box display="flex" alignItems="center">
          <InfoOutlinedIcon color="primary" />
          <Typography variant="h5" color="primary" style={{ padding: "10px" }}>
            Tip
          </Typography>
        </Box>
      )}
      <ReactMarkdown
        className="markdown-content"
        components={{
          p: ({ node, ...props }) => (
            <p style={{ marginBottom: "0.8rem" }} {...props} />
          ),
        }}
      >
        {displayTipText && (area.tip || "")}
      </ReactMarkdown>
      {displayFiles && area.files && Array.isArray(area.files) && (
        <Box display="flex" flexDirection="column" gap="10px" mt="10px">
          {area.files
            .reduce((rows, file, index) => {
              if (index % 3 === 0) rows.push([]);
              rows[rows.length - 1].push(
                <DownloadButton
                  key={file.path}
                  filename={file.path}
                  buttonText={file.label}
                />
              );
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <Box key={rowIndex} display="flex" gap="0.5rem" mb="0.5rem">
                {row}
              </Box>
            ))}
        </Box>
      )}
      {displayImage && area.tip_image_path && (
        <img
          src={area.tip_image_path}
          alt="Tip"
          style={{ width: "100%", height: "auto" }}
        />
      )}
    </Box>
  );
};

export default TipSection;

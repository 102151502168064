import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import logo from "../logo-white.jpg";
// import { useSnackbar } from '../contexts/SnackbarContext';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  // const { showSnackbar } = useSnackbar();

  const handleForgotPassword = () => {
    setLoading(true);
    setMessage("");

    fetch(process.env.REACT_APP_API_URL + "/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setIsSuccess(true);
          return;
        }
        setMessage(data.message);
      })
      .catch((error) => {
        console.error("Forgot Password Error:", error);
        setLoading(false);
        setMessage("Der opstod en fejl. Prøv venligst igen.");
      });
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              width: "100%",
              maxWidth: "530px",
            }}
          >
            {isSuccess ? (
              <>
                <Typography component="h1" variant="h5" mt={2} gutterBottom>
                  Bekræftelsesmail sendt
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  En mail er blevet sendt til dig for nulstilling af
                  adgangskoden.
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("/logind")}
                >
                  Tilbage til log ind
                </Button>
              </>
            ) : (
              <>
                <Typography component="h1" variant="h5" mt={2}>
                  Email til nulstilling af adgangskode
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleForgotPassword}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                    helperText="Indtast den email du gerne vil nulstille adgangskoden for. En mail vil blive sendt til dig efterfølgende."
                  />
                  <Button
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={handleForgotPassword}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Send bekræftelsesmail"
                    )}
                  </Button>
                  {message && (
                    <Typography variant="body2" color="error">
                      {message}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    <Button color="primary" onClick={() => navigate("/logind")}>
                      Tilbage til log ind
                    </Button>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;

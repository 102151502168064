import React, { useEffect, useState } from "react";
import { CircularProgress, Box } from "@mui/material";

const StripeBuyButton = () => {
  const [clientReferenceId, setClientReferenceId] = useState(null);
  const [, setStripeCustomerId] = useState(null);
  const [customerSessionClientSecret, setCustomerSessionClientSecret] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);

  // Handle user data initialization
  useEffect(() => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData?.company?.id) {
        setClientReferenceId(userData.company.id);
      }
      if (userData?.company?.stripeCustomerId) {
        setStripeCustomerId(userData.company.stripeCustomerId);
      }
    } catch (error) {
      console.error("Error accessing user data:", error);
      setError("Error accessing user data");
    }
  }, []);

  // Handle Stripe script loading
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Handle client session creation
  useEffect(() => {
    const fetchClientSession = async () => {
      if (!clientReferenceId) return;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await fetch(
          process.env.REACT_APP_API_URL + "/stripe/client-sessions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to create session");
        }

        const data = await response.json();
        setCustomerSessionClientSecret(data.session.client_secret);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientSession();
  }, [clientReferenceId]);

  if (loading || !clientReferenceId || clientReferenceId === "null") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <stripe-buy-button
      buy-button-id={
        process.env.NODE_ENV === "development"
          ? "buy_btn_1QTlgAGLSYg6xs7Ukygeei62"
          : "buy_btn_1QUV3KGLSYg6xs7UczRxoldY"
      }
      publishable-key={
        process.env.NODE_ENV === "development"
          ? "pk_test_51QRzkkGLSYg6xs7UvbWb3Hgmi6GTbSf9Ai8Wz6tuzCpy449vnddxQ0mN3zfN67UdTgDGsKoKWKbsG8Mky5Zszlbh00kIkpd2ES"
          : "pk_live_51QRzkkGLSYg6xs7Uvx98zFmzpwSBja5tSvJ4BYQlQF8UuxJzV1tE25Jspx9CRwQLHAS7Wc57t0YsBH1KndNHcHXK00DEA0ob6G"
      }
      client-reference-id={clientReferenceId}
      {...(customerSessionClientSecret && {
        "customer-session-client-secret": customerSessionClientSecret,
      })}
    ></stripe-buy-button>
  );
};

export default StripeBuyButton;

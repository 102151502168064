// src/pages/StartPage.js
import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import logo from "../logo-white.jpg";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="max-h-[80vh] border border-gray-300 overflow-y-auto p-10"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Privatlivspolitik for DitESG ApS
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Introduktion</Typography>
              <Typography paragraph>
                Hos DitESG prioriterer vi beskyttelsen af vores kunders data
                højt. Denne privatlivspolitik beskriver, hvordan vi indsamler,
                anvender og beskytter de oplysninger, som vores brugere
                indtaster i vores system, herunder virksomhedsdata, miljø-,
                sociale og ledelsesmæssige data (ESG-data) samt
                virksomhedsindstillinger.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Dataansvarlig</Typography>
              <Box sx={{ pl: 2, mt: 1 }}>
                <Typography variant="body1">DitESG ApS</Typography>
                <Typography variant="body1">Kærdalen 21</Typography>
                <Typography variant="body1">3660 Stenløse</Typography>
                <Typography variant="body1">CVR-nummer: 44924404</Typography>
                <Typography variant="body1">
                  Kontaktperson: Mette Fuglsang Grøn
                </Typography>
                <Typography variant="body1">
                  E-mail: kontakt@ditesg.dk
                </Typography>
                <Typography variant="body1">
                  Telefon: +45 25 11 33 98
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Indsamling af oplysninger</Typography>
              <Typography variant="body1">
                Vi indsamler og behandler følgende typer af oplysninger:
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Virksomhedsdata: Oplysninger om din virksomhed, herunder navn, adresse, kontaktoplysninger og CVR-nummer." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="ESG-data: Data relateret til din virksomheds miljømæssige, sociale og ledelsesmæssige forhold." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Virksomhedsindstillinger: Præferencer og indstillinger, som du konfigurerer i vores system." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Data fra Virk: Oplysninger indhentet via integration med Virk.dk, som kan omfatte offentligt tilgængelige virksomhedsoplysninger." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Formål med behandling</Typography>
              <Typography variant="body1">
                De indsamlede oplysninger anvendes til følgende formål:
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Levering af tjenester: For at kunne levere vores softwareløsninger og tjenester til din virksomhed." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Support: For at yde teknisk support og besvare henvendelser." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Forbedring af tjenester: For at analysere brugen af vores tjenester og forbedre funktionaliteten." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Retsgrundlag for behandling</Typography>
              <Typography variant="body1">
                Vores behandling af personoplysninger baserer sig på følgende
                retsgrundlag:
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Opfyldelse af kontrakt: Behandlingen er nødvendig for at opfylde den kontrakt, din virksomhed har indgået med os." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Legitim interesse: Behandlingen er nødvendig for at forfølge vores legitime interesser i at forbedre vores tjenester og sikre deres sikkerhed." />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Opbevaring af data</Typography>
              <Typography variant="body1">
                Vi opbevarer dine oplysninger, så længe det er nødvendigt for at
                opfylde de formål, hvortil de er indsamlet, eller for at
                overholde lovmæssige forpligtelser.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Sikkerhed</Typography>
              <Typography variant="body1">
                Vi har implementeret passende tekniske og organisatoriske
                foranstaltninger for at beskytte dine data mod uautoriseret
                adgang, ændring, offentliggørelse eller destruktion.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Videregivelse af oplysninger</Typography>
              <Typography variant="body1">
                Vi videregiver ikke dine oplysninger til tredjepart uden dit
                samtykke, medmindre det er nødvendigt for at opfylde en juridisk
                forpligtelse eller for at levere vores tjenester (f.eks. ved
                brug af underdatabehandlere).
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Dine rettigheder</Typography>
              <Typography variant="body1">
                Du har følgende rettigheder i forhold til vores behandling af
                dine personoplysninger:
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ret til indsigt: Du kan anmode om at få indsigt i de oplysninger, vi behandler om din virksomhed." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ret til berigtigelse: Du kan anmode om at få rettet urigtige eller ufuldstændige oplysninger." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ret til sletning: Du kan anmode om at få slettet oplysninger, der ikke længere er nødvendige for de formål, de blev indsamlet til." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ret til begrænsning af behandling: Du kan anmode om at få begrænset behandlingen af dine oplysninger under visse omstændigheder." />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Ret til dataportabilitet: Du kan anmode om at få udleveret de oplysninger, du har givet os, i et struktureret, almindeligt anvendt og maskinlæsbart format." />
                </ListItem>
              </List>
              <Typography variant="body1">
                For at udøve dine rettigheder kan du kontakte os på ovenstående
                kontaktoplysninger.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">
                Ændringer til privatlivspolitikken
              </Typography>
              <Typography variant="body1">
                Vi forbeholder os retten til at opdatere denne
                privatlivspolitik. Eventuelle ændringer vil blive offentliggjort
                på vores hjemmeside, og væsentlige ændringer vil blive meddelt
                direkte til vores kunder.
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Klageadgang</Typography>
              <Typography variant="body1">
                Hvis du er uenig i vores behandling af dine personoplysninger,
                har du ret til at indgive en klage til Datatilsynet.
              </Typography>
              <Typography variant="body1">
                Denne privatlivspolitik er senest opdateret den 10 december
                2024.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;

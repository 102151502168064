import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import logo from "../logo-white.jpg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// import { useSnackbar } from '../contexts/SnackbarContext';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  // const { showSnackbar } = useSnackbar();

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setMessage("Adgangskoderne stemmer ikke overens.");
      return;
    }

    setLoading(true);
    setMessage("");

    fetch(process.env.REACT_APP_API_URL + "/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        email,
        password,
        password_confirmation: confirmPassword,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setIsSuccess(true);
          return;
        }
        setMessage(data.message);
      })
      .catch((error) => {
        console.error("Reset Password Error:", error);
        setLoading(false);
        setMessage("Der opstod en fejl. Prøv venligst igen.");
      });
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              src={logo}
              alt={process.env.REACT_APP_COMPANY_NAME}
              style={{ maxWidth: "100%", height: "auto", maxHeight: "75px" }}
            />
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              width: "100%",
              maxWidth: "530px",
            }}
          >
            {isSuccess ? (
              <>
                <Typography component="h1" variant="h5" mt={2} gutterBottom>
                  Adgangskode gemt
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Din nye adgangskode er gemt, og du kan fortsætte til
                  loginsiden.
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("/logind")}
                >
                  Tilbage til log ind
                </Button>
              </>
            ) : (
              <>
                <Typography component="h1" variant="h5" mt={2}>
                  Vælg ny adgangskode
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Ny adgangskode"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={loading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Bekræft ny adgangskode"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    disabled={loading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={handleResetPassword}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Gem adgangskode"
                    )}
                  </Button>
                  {message && (
                    <Typography variant="body2" color="textSecondary">
                      {message}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button color="primary" onClick={() => navigate("/logind")}>
                    Tilbage til login
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetPassword;

// src/hooks/useVerifyAccess.js
import { useState, useEffect, useCallback } from "react";

const useVerifyAccess = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [hasAccessIsLoading, setHasAccessIsLoading] = useState(true);
  const [hasAccessError, setHasAccessError] = useState(null);

  const verifyAccess = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setHasAccess(false);
      setHasAccessIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company/verify-access`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setHasAccess(data.hasAccess);
      setHasAccessError(null);
    } catch (err) {
      setHasAccessError(err.message);
      setHasAccess(false);
    } finally {
      setHasAccessIsLoading(false);
    }
  }, []);

  useEffect(() => {
    verifyAccess();
  }, [verifyAccess]);

  return {
    hasAccess,
    hasAccessIsLoading,
    hasAccessError,
    refetch: verifyAccess,
  };
};

export default useVerifyAccess;

import React from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from '../contexts/SnackbarContext';


const DownloadButton = ({ filename, buttonText = "Download File" }) => {
  const { showSnackbar } = useSnackbar();
  const handleDownload = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/download-file/${filename}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (!response.ok) {
                if (response.status === 404) {
                    showSnackbar("Kunne ikke finde den efterspurgte fil", "error");
                } else if (response.status === 500) {
                    showSnackbar('Hov... Der opstod en fejl. Kontakt venligst supporten.', 'error');
                }
                throw new Error('Failed to download file');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            // Create a link element, set its href to the blob URL, and trigger a click to download
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // The downloaded file's name
            document.body.appendChild(a); // Append the link to the body
            a.click(); // Trigger the download
            a.remove(); // Remove the link from the DOM

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
            {buttonText}
        </Button>
    );
};

export default DownloadButton;

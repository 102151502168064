import React, { useState, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import FormPage from "./pages/FormPage";
import Login from "./pages/Login";
import CreateCompany from "./pages/CreateCompany";
import ForgotPassword from "./pages/ForgotPassword";
import Report from "./pages/Report";
import StartPage from "./pages/StartPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { PermissionsProvider } from "./contexts/PermissionsContext";
import { RolesProvider } from "./contexts/RolesContext";
import ResetPassword from "./pages/ResetPassword";
import License from "./pages/License";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// import "@n8n/chat/style.css";
// import "./styles/n8n-overrides.css"; // Ensure this is imported after the n8n styles
// import { createChat } from "@n8n/chat";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [isDataModified, setIsDataModified] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#006A61",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#4cb58f",
        contrastText: "#ffffff",
      },
    },
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // useEffect(() => {
  //   try {
  //     createChat({
  //       webhookUrl:
  //         "https://planmateai.app.n8n.cloud/webhook/e985d15f-b2f6-456d-be15-97e0b1544a40/chat",
  //       initialMessages: [
  //         "Hej 👋",
  //         "Jeg hedder Asgar og hjælper med ESG-spørgsmål 😊",
  //       ],
  //       defaultLanguage: "dk",
  //       i18n: {
  //         dk: {
  //           title: "Velkommen 👋",
  //           subtitle:
  //             "Stil mig alle dine spørgsmål om DitESG. Jeg er i beta, så der kan være fejl 😉",
  //           footer: "",
  //           getStarted: "Start en ny samtale",
  //           inputPlaceholder: "Skriv dit spørgsmål her...",
  //         },
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDataModified) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // When user presses back/forward button in browser
    window.onpopstate = (event) => {
      if (isDataModified) {
        setIsDataModified(false);
      }
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDataModified]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <PermissionsProvider>
          <RolesProvider>
            <Router>
              <Navbar
                onToggleDarkMode={toggleDarkMode}
                isDataModified={isDataModified}
                setIsDataModified={setIsDataModified}
              />
              <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/logind" element={<Login />} />
                <Route path="/glemt-adgangskode" element={<ForgotPassword />} />
                <Route
                  path="/nulstil-adgangskode"
                  element={<ResetPassword />}
                />
                <Route path="/opret-virksomhed" element={<CreateCompany />} />
                <Route path="/servicevilkaar" element={<TermsOfService />} />
                <Route path="/privatlivspolitik" element={<PrivacyPolicy />} />

                <Route
                  path="/koeb-licens"
                  element={
                    <ProtectedRoute>
                      <License />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/indstillinger"
                  element={
                    <ProtectedRoute>
                      <Settings
                        isDataModified={isDataModified}
                        setIsDataModified={setIsDataModified}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/form"
                  element={
                    <ProtectedRoute>
                      <FormPage
                        isDataModified={isDataModified}
                        setIsDataModified={setIsDataModified}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/rapport"
                  element={
                    <ProtectedRoute>
                      <Report />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Router>
          </RolesProvider>
        </PermissionsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
